export const productImageMap: Record<string, string> = {
    candy: '01. Candy (Petite Size, Regular).png',
    puppucino: '02. Puppucino (Mid Size, Beginner).png',
    greyscale: '03. Greyscale Monochrome (Mid Size, Regular).png',
    paddlepup: '04. Paddlepup (Mid Size, Regular).png',
    confetti: 'Snuffle Mat (Confetti, Surprise Me).png',
    // Add more products here as needed
};

export const tugStickImageMap: Record<string, string> = {
    tugstik: 'TUG STIK 2.png',
    xstik: 'X STIK.png',
    doublehalo: 'INFINITY RINGS.png',
    halo: 'OCTO REG.png'
}

export const snuffleBallImages: string[] = ['BOL 1.png', 'BOL 2.png', 'BOL 3.png', '04. Paddlepup (Mid Size, Regular).png']

export const snuffleMatImages: string[] = ['Snuffle Mat (Confetti, Surprise Me).png', 'MAT Rainbow - Stripe.png', 'MAT Surprise Me - Circle.png']

export const tugStickImages: string[] = ['TUG STIK 2.png', 'TUG STIK REG.png', 'TUG STIK W_ KNOT.png']

export const xStickImages: string[] = ['X STIK.png', 'X STIK 1.png']

export const doubleHaloImages: string[] = ['INFINITY RINGS.png', 'INFINITY RINGS 2.png']

export const haloImages: string[] = ['OCTO REG.png', 'OCTO SMOL.png', 'OCTO-STIK REG.png']
