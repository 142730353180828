import React, { useEffect } from 'react';
import UserDetailsForm from '../UserDetailsForm';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { RootState } from '../../store';
import { selectToken, userId } from "../../features/authSlice";
import Cart from './Cart';
import { InvoiceItem, InvoiceRequestBody } from "../../models/Cart";
import Divider from '@mui/material/Divider';
import "../../styles/Checkout.scss";
import { AppDispatch } from '../../store';
import { createInvoiceAsync, createInvoiceResponseData, CreateInvoiceItemsResponse } from "../../features/invoiceSlice";
import { HitPayproduct, HitPayInvoiceRequest, HitPayCustomer } from '../../models/Payment';
import { createHitPayInvoiceAsync, paymentLoading, paymentData } from "../../features/checkoutSlice";
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Card, Button } from "@material-tailwind/react";


interface ProductToHitPayRequest {
    quantity: string;
    name: string;
    price: string;
    description: string;
    discount?: string;
    // Add other fields as needed
}

const CheckoutPage: React.FC = () => {
    let navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:1000px)');
    const dispatch = useDispatch<AppDispatch>()
    const cartItems = useSelector((state: RootState) => state.cart.items);
    const promoCodeValue = useSelector((state: RootState) => state.invoices.promoCodeValue);
    const finalPricePostDiscount = useSelector((state: RootState) => state.cart.totalPricePostCalculation)
    const promoCodeString = useSelector((state: RootState) => state.invoices.promoCodeString)
    const promoCodeType = useSelector((state: RootState) => state.invoices.promoCodeType)
    const streetAddress = useSelector((state: RootState) => state.checkout.streetAddress);
    const phoneNumber = useSelector((state: RootState) => state.checkout.phoneNumber);
    const postalCode = useSelector((state: RootState) => state.checkout.postalCode);
    const firstName = useSelector((state: RootState) => state.checkout.firstName);
    const lastName = useSelector((state: RootState) => state.checkout.lastName);
    const email = useSelector((state: RootState) => state.checkout.email);
    const petIG = useSelector((state: RootState) => state.checkout.petIG)
    const userID = useSelector(userId)
    const token = useSelector(selectToken);
    const invoiceData = useSelector(createInvoiceResponseData)
    const isPaymentLoading = useSelector(paymentLoading);
    const paymentResponse = useSelector(paymentData)
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false)
        
        navigate('/');
        window.location.reload()
    }

    const getTodayDateString = (): string => {
        const today: Date = new Date();
        const year: string = today.getFullYear().toString();
        let month: string | number = today.getMonth() + 1; // Month is zero-based
        let day: string | number = today.getDate();
    
        // Pad month and day with leading zeros if needed
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
    
        return `${year}-${month}-${day}`;
    }

    function convertInvoiceItemsToProducts(invoiceItems: CreateInvoiceItemsResponse[]) : HitPayproduct[] {
        const products: HitPayproduct[] = []
        let currIdx = 0
        let totalPrice = 0
        invoiceItems.forEach((item, index) => {
            totalPrice += (item.price * item.quantity)
            currIdx = index + 1
            const name: string = `${item.product_type} - ${item.product_name}`; // Example name construction
            const price: string = item.price.toString(); // Convert price to string
            const description: string = `Colors chosen: ${item.color_string}, level: ${item.level ? item.level : "NA"}, size: ${item.size ? item.size : "NA"}`; // Example description construction
            let product: ProductToHitPayRequest = {
                quantity: "",
                    name: "",
                    price: "",
                    description: "",
                    discount: ""
            }

            if (promoCodeType === 'percentage') {
                product = {
                    quantity: item.quantity.toString(),
                    name: name,
                    price: price,
                    description: description,
                    discount: (((promoCodeValue/100) * item.price) * item.quantity).toFixed(2)
                }
            } else if (promoCodeType === 'value' && index === 0) {
                product = {
                    quantity: item.quantity.toString(),
                    name: name,
                    price: price,
                    description: description,
                    discount: promoCodeValue.toFixed(2)
                }
            } else {
                product = {
                    quantity: item.quantity.toString(),
                    name: name,
                    price: price,
                    description: description,
                    discount: "0"
                }
            }
            

            products.push(product)
        })

        if (totalPrice < 80) {
            const deliveryFee: ProductToHitPayRequest = {
                quantity: "1",
                name: "Shipping Fee",
                price: "4",
                description: "Shipping Fee"
            } 
            products.push(deliveryFee)
        }
        
        return products
    }

    // function convertInvoiceItemsToProducts(invoiceItems: CreateInvoiceItemsResponse[]): { [key: string]: ProductToHitPayRequest } {
    //     const products: { [key: string]: ProductToHitPayRequest } = {};

    //     let totalPrice = 0
    //     let currIdx = 0
    //     invoiceItems.forEach((item, index) => {
    //         // Assuming you construct name, price, and description based on invoice item properties
    //         totalPrice += (item.price * item.quantity)
    //         currIdx = index + 1
    //         const name: string = `${item.product_type} - ${item.product_name}`; // Example name construction
    //         const price: string = item.price.toString(); // Convert price to string
    //         const description: string = `Colors chosen: ${item.color_string}, level: ${item.level ? item.level : "NA"}, size: ${item.size ? item.size : "NA"}`; // Example description construction
    
    //         // Construct the product object
    //         const product: ProductToHitPayRequest = {
    //             quantity: item.quantity.toString(),
    //             name: name,
    //             price: price,
    //             description: description,
    //             discount: (((discount/100) * item.price) * item.quantity).toFixed(2)
    //             // Add other fields as needed
    //         };
    
    //         // Assign the product to the products object with the index as key
    //         products[index.toString()] = product;
    //     });

        
        
    
    //     if (totalPrice < 80) {
            // products[currIdx] = {
            //     quantity: "1",
            //     name: "Shipping Fee",
            //     price: "4",
            //     description: "Shipping Fee"
            // }
    //     }

    //     return products;
    // }

    useEffect(() => {
        
        
        if (invoiceData && Object.keys(invoiceData).length !== 0) {
            const products: HitPayproduct[] = convertInvoiceItemsToProducts(invoiceData.invoice_items);
            let todayDate = getTodayDateString()
            const requestBody: HitPayInvoiceRequest = {
                customer: {
                    name: invoiceData.customer_name,
                    email: invoiceData.customer_email,
                    address: {
                        street: streetAddress,
                        city: "Singapore",
                        state: "Singapore",
                        postal_code: postalCode.postalCode,
                        country: "Singapore"
                    },
                    phone_number: phoneNumber
                },
                invoice_date: todayDate,
                currency: "SGD",
                payment_by: "product",
                auto_invoice_number: "1",
                due_date: todayDate,
                send_email: 1,
                recipients: [
                    {
                        "email": invoiceData.customer_email
                    }
                ],
                reference: petIG !== "" ? `Pet Instagram: ${petIG}` : "",
                products: products
            }

            
            dispatch(createHitPayInvoiceAsync({ invoiceRequest: requestBody }))
            // let invoiceID = invoiceData['invoice_id']
            // const requestBody: PaymentRequestBody = {
            //     amount: finalPricePostDiscount,
            //     currency: "SGD",
            //     email: email,
            //     redirect_url: `http://www.curiousone.co`,
            //     webhook: "http://13.213.11.1/payment_requests/confirmation/webhook",
            //     reference_number: invoiceID.toString(),
            //     phone: phoneNumber
            // }
            // dispatch(createPaymentAsync({ paymentRequest: requestBody }))
        }
    }, [invoiceData])

    useEffect(() => {
        if (!isPaymentLoading && paymentResponse) {
            window.location.href = paymentResponse.url
        }
    }, [isPaymentLoading])

    const handlePayNow = () => {
        const invoiceItems: InvoiceItem[] = cartItems.map(item => {
            const invoiceItem: InvoiceItem = {
                product_id: Number(item.id),
                product_type: item.type,
                product_name: item.name,
                price: item.price,
                quantity: item.quantity,
                size: item.size,
                level: item.level
            };
            if (item.colors.length > 0) {
                invoiceItem.color = item.colors;
            }
            return invoiceItem;
        })

        const requestBody: InvoiceRequestBody = {
            buyer_name: firstName + " " + lastName,
            description: "Snuffle Bol",
            total_price: finalPricePostDiscount,
            invoice_items: invoiceItems,
            street_address: streetAddress,
            phone_number: phoneNumber,
            postal_code: postalCode.postalCode,
            email_address: email,
            promo_code_used: promoCodeString
        };
        
        const customerRequestBody: HitPayCustomer = {
            name: firstName + " " + lastName,
            email: email,
            phone_number: phoneNumber,
            address: {
                city: "Singapore",
                state: "Singapore",
                street: streetAddress,
                postal_code: postalCode.postalCode,
                country: "Singapore"
            }
        }

        // dispatch(createHitPayCustomerAsync({customerRequest: customerRequestBody}))
        dispatch(createInvoiceAsync({ cartRequest: requestBody, token: token! }))
        setOpen(true)
    }

    return (
        <section className="px-8 py-20">
            <div className="container mx-auto">
            <div className="mb-14 text-center ">
                <h1 className="mb-4 text-4xl !leading-snug lg:text-[40px]">Check Out</h1>
            </div>
            <div className="mx-auto container md:flex md:flex-row h-full md:gap-20">

                <Card className="md:basis-2/3 p-20 rounded-3xl" placeholder="" color="white">
                    <UserDetailsForm handlePayNow={handlePayNow} />
                </Card>
                
                <div className="md:basis-1/3 md:pt-10">
                    <Cart />
                </div>
            </div>
            </div>



            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                <Box className="max-w-[24rem] border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="flex p-6 bg-gray-500 text-white rounded-t-3xl">
                        <h3 className="text-xl font-semibold grow self-center">Thank you for your order!</h3>
                        <Button placeholder="" onClick={handleClose} className="self-center bg-transparent p-0 shadow-none hover:shadow-none hover:text-gray-300">
                            <CloseIcon />
                        </Button>
                    </div>

                    <div className="relative p-6 flex-auto">
                        <p>Your order invoice has been sent to your email. Please check your email for payment instructions.</p>
                    </div>
                </Box>
            </Modal>
        </section>
    );
};

export default CheckoutPage;
