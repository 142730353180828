import React, { useEffect } from "react"
import MuiCard from "./cards/MuiCard"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { fetchProductByTypeAsync, currProductType, productData, resetState } from "../features/productSlice";
import { tugStickImageMap } from '../common/imageMap'
import "../styles/ProductTypeSelection.scss";

interface ProductTypeSelectionPage {
    productType: string
}

interface Product {
    name: string;
    price: number;
    image: string;
}

export function ProductListCard({image,name,price}: Product) {
    let navigate = useNavigate();
    const handleButtonClick = (productName: string) => {
        
        navigate(`/tugtoy/customise/${productName}`);
    }
    return (
        <div className="productInfo" onClick={()=>handleButtonClick(name)}>
            <div className="flex justify-center rounded-2xl border-transparent bg-white p-5 shadow-lg shadow-gray-50/20 itemImage">
                <img src={image} alt={name} className="max-h-[150px] md:max-h-[200px]" />
            </div>
            <div className="text-center mt-2 itemInfo">
                <div><h5 className="font-bold md:mt-5">{name}</h5></div>
                <div className="text-gray-500 italic">from ${price.toFixed(2)}</div>
            </div>
        </div>
    );
  }

const ProductTypeSelectionPage: React.FC<ProductTypeSelectionPage> = ({ productType }) => {
    const dispatch = useDispatch<AppDispatch>();
    let navigate = useNavigate();
    const currentProductType = useSelector(currProductType)
    const productLoaded = useSelector(productData)
    const productTypes = useSelector((state: RootState) => state.products.productType)
    const renderedProductNames: { [key: string]: boolean } = {};

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const data = await dispatch(fetchProductByTypeAsync({ productType: productType }));
            } catch (error) {
                console.error("Error fetching product: ", error)
            }
        }
        if (productType != currentProductType) {
            dispatch(resetState())
            fetchProduct();
        }
    }, [productType, dispatch])

    const handleButtonClick = (productName: string) => {
        
        navigate(`/tugtoy/customise/${productName}`);
    }

    return (
        <div className="mx-auto container">
            <h3 className="block font-sans text-3xl antialiased font-semibold leading-snug tracking-normal text-inherit text-center my-6">Tug</h3>
            <div className="text-center">Apart from snuffling, tug activities can be just as enriching and as fun for the puppers! Introducing a variety of tug products for all kinds of tuggers! If you’re looking to buy any of the items in bulk, special bulk pricing is available for all gifting and party needs! Feel free to contact us at our email or drop us a DM on our Instagram page!</div>
            <div className="m-3 grid grid-cols-1 gap-8 lg:grid-cols-2 md:grid-cols-2 md:mx-0">
            {productLoaded.length > 0 ? productLoaded.map(product => {
                if (product.name === 'TUG STICK' && !renderedProductNames[product.name]) {
                    
                    // Render the MuiCard for 'TUG STICK'
                    renderedProductNames[product.name] = true; // Mark as rendered
                    return (<ProductListCard key={product.name} image={tugStickImageMap[product.name.toLowerCase().replace(/[\s-]/g, '')]} name={product.name} price={product.price}/>);
                } else if (!renderedProductNames[product.name]) {
                    // Render the MuiCard for other products
                    renderedProductNames[product.name] = true; // Mark as rendered
                    return (<ProductListCard key={product.name} image={tugStickImageMap[product.name.toLowerCase().replace(/[\s-]/g, '')]} name={product.name} price={product.price}/>);
                } else {
                    // If the product has already been rendered, don't render anything
                    return null;
                }
            })
                : "Loading"}
            </div>
        </div>
    )
}

export default ProductTypeSelectionPage