export const detailsMap: Record<string, string> = {
    "Snuffle Bol": `Our staple Snuffle Bol (“ball”) is basically a snuffle mat shaped like a ball. Created with the same non-toxic fabric as the snuffle mat, the bol is a step up in difficulty from the mat because of its unique round shape. Not only do snufflers have to use their nose to sniff and search for the yummy treatos, they would also need to engage more of their mental capacity to decide how to flip open each flap, and to turn the bols around with their faces and even paws. It is the perfect outlet for extended mental stimulation! 

    STYLE </br>
    The default colour pattern is CONFETTI, where all colours chosen are scattered and mixed. 
    If you wish, you may choose the BLOCK pattern, where the same colours are generally kept together. 
    <br/><br/>
    SIZES </br>
    The Snuffle Bol is available in 3 diameter sizes:
    <br/>MEGA ~20cm
    <br/>MID ~18cm
    <br/>MINI ~14cm
    </br></br>
    DIFFICULTY LEVEL </br>
    REGULAR difficulty level is the default, and generally great for most pets, while the BEGINNER level is for pets who are completely new to snuffle balls. 
    </br>
    </br>
    COLOURS </br>
    No 2 bols are the same! Customise your bol by choosing 4 complimentary colours or even more if you wish! Each additional colour is at +$5.
    <br/></br>
    Please see CARE INSTRUCTIONS for more details on how to maintain your Curious One product!
    
    `,
    "Snuffle Mat": `The all-time favourite Snuffle Mat is nothing new to prolific sniffers. It lays flat, and is easy for humans to scatter small pieces of treats or pet food within its flaps and crevices. It’s the perfect way for pets to kick start their snuffling journey especially for beginners. Watch as your little furry companion discovers the instinctive joy of foraging for food. For speed eaters, this is also the perfect way to feed a meal–slowing them down to aid in digestive health, whilst engaging their senses for mental stimulation, a win-win situation for all!

    Suitable for pets of all ages (e.g. dogs, cats, rabbits etc. or those who engage in sniffing/foraging). 
    <br/></br>
    STYLE<br/>
    The default colour pattern is CONFETTI, where all colours chosen are scattered and mixed. <br/>
    If you wish, you may choose the BLOCK pattern, where the same colours are generally kept together. <br/>
    The STRIPE pattern keeps the chosen colours in strips next to each other. 
    <br/></br>
    SIZE<br/>
    Approx. 33cm x 29cm
    <br/></br>
    COLOURS<br/>
    Customise your mat by choosing 4 complimentary colours or even more if you wish! Each additional colour is at +$5
    <br/></br>
    Please see CARE INSTRUCTIONS for more details on how to maintain your Curious One product!
    
    `,
    "TUG STIK": `Simple, but mighty fun for all pets who enjoy tugging and fetching! Available in 2 different lengths to cater to differently sized pets, and the knot option for those who enjoy a little more grip!
    <br/><br/>
    STYLE<br/>
    STIK: Simple stik available in 2 lengths (select below) <br/>
    KNOT: ~35cm in length
    <br/><br/>
    SIZES
    (measured end to end)
    REGULAR ~30cm
    LONG ~50cm
    <br/><br/>
    COLOURS<br/>
    Customise your mat by choosing 4 complimentary colours or even more if you wish! Each additional colour is at +$5
    
    `,
    "X-STIK": `
    Like the regular Tug Stik, but 4 ways instead, perfect for multi-dog households with dogs who love tugging! Available in 2 different sizes to cater to petite & bigger sized doggos. 
    <br/><br/>
    SIZES
    (measured end to end)<br/>
    REGULAR ~40cm<br/>
    MEGA ~50cm
    <br/><br/>
    COLOURS<br/>
    Customise your mat by choosing 4 complimentary colours or even more if you wish! <br/>Each additional colour is at +$5

    `,
    "DOUBLE HALO": `
    The Double Halo provides an extra challenge for the tuggers! Made up of essentially 2 Halos, the Double Halo caters perfectly to multi-dog households who love playing tug together! The loops make it fun even just for a human and a dog to grab onto during play, making it extra secure, extra fun! 
    <br/><br/>
    SIZE
    (measured end to end)<br/>
    REGULAR ~50cm
    <br/><br/>
    COLOURS<br/>
    Customise your mat by choosing 4 complimentary colours or even more if you wish! <br/>Each additional colour is at +$5

    `,
    "HALO": `
    Shaped as a circle instead of a stick, the Halo provides a different grip texture for the dogs. For athletic ones, you could even fling it as a frisbee and watch them play fetch! Versatile and beautiful, the Halo is truly a statement piece of a tug toy that could even be the perfect gift for a furry friend! 
    <br/><br/>
    SIZES
    (measured end to end)<br/>
    REGULAR ~20cm<br/>
    MEGA ~30cm
    <br/><br/>
    COLOURS<br/>
    Customise your mat by choosing 4 complimentary colours or even more if you wish! <br/> Each additional colour is at +$5

    `
}