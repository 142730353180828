import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { useParams  } from "react-router-dom";
import "../styles/ProductSelection.scss";
import ColorPalette from "./ColorPalette";
import { addToCart } from '../features/cartSlice';
import { CartItem, Product } from "../common/types";
import { Color } from "../models/Color";
import { productData, fetchProductByTypeAsync, fetchProductByNameAsync, selectProductTypeLoading, setBasePrice, colorState, currProductType, resetColors, basePrice, resetState, updateColorList } from '../features/productSlice';
import { tugStickImages, xStickImages, doubleHaloImages, snuffleBallImages, snuffleMatImages, haloImages } from "../common/imageMap";
import MuiDropdownButtonWrapper from "./buttons/MuiDropDownButtonWrapper";
import TabComponent from "./TabComponent";
import MuiButton from "./buttons/MuiButton";
import { fetchColorsAsync } from '../features/colorSlice';
import { detailsMap } from '../common/textContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, Carousel, Tabs,TabsHeader,TabsBody,Tab,TabPanel, } from "@material-tailwind/react";

interface ProductSelectionPage {
    productType: string;
    fromTypeSelection: boolean;
}

const ProductSelectionPage: React.FC<ProductSelectionPage> = ({ productType, fromTypeSelection }) => {
    const { productName } = useParams<{ productName: string }>();
    const [productImages, setProductImages] = useState<string[]>([])
    const productTypes = useSelector((state: RootState) => state.products.productType)
    const size = useSelector((state: RootState) => state.products.size)
    const level = useSelector((state: RootState) => state.products.level)
    const isMobile = useMediaQuery('(max-width:1000px)');
    let imageToPick: string[]
    if (productType == 'Snuffle Bol') {
        imageToPick = snuffleBallImages
    } else if (productType == 'Snuffle Mat') {
        imageToPick = snuffleMatImages
    } else if (productName == 'TUG STIK') {
        imageToPick = tugStickImages
    } else if (productName == 'X-STIK') {
        imageToPick = xStickImages
    } else if (productName == 'DOUBLE HALO') {
        imageToPick = doubleHaloImages
    } else {
        imageToPick = haloImages
    }
    const dispatch = useDispatch<AppDispatch>();
    const productLoaded = useSelector(productData)
    const isLoading = useSelector(selectProductTypeLoading)
    const colorStateFromSelector = useSelector(colorState)
    const currentProductType = useSelector(currProductType)
    const [selectedOption, setSelectedOption] = useState<string>("");
    const [selectedSize, setSelectedSize] = useState<string>("mega");
    const [selectedLevel, setSelectedLevel] = useState<string>("regular");
    const [textareaValue, setTextareaValue] = useState("");
    const [expandColors, setExpandColors] = useState(false);
    const [productList, setProductList] = useState<Product[]>([])
    const [currentPrice, setCurrentPrice] = useState(0)
    const baseSelectedPrice = useSelector(basePrice)
    
    const [paletteList, setPaletteList] = useState([])

    useEffect(() => {
        setExpandColors(false)
        const fetchColor = async () => {
            try {
                const data = await dispatch(fetchColorsAsync())
                
                setPaletteList(data.payload)
            } catch (error) {
                console.error("Error fetching colors: ", error)
            }
        }

        fetchColor()
    }, [])

    useEffect(() => {
        if (productType == 'Snuffle Bol') {
            setProductImages(snuffleBallImages)
        } else if (productType == 'Snuffle Mat') {
            setProductImages(snuffleMatImages)
        } else if (productName == 'TUG STIK') {
            setProductImages(tugStickImages)
        } else if (productName == 'X-STIK') {
            setProductImages(xStickImages)
        } else if (productName == 'DOUBLE HALO') {
            setProductImages(doubleHaloImages)
        } else {
            setProductImages(haloImages)
        }
        const fetchProduct = async () => {
            try {
                const data = await dispatch(fetchProductByTypeAsync({ productType: productType }));
            } catch (error) {
                console.error("Error fetching product: ", error)
            }
        }

        const fetchProductByName = async () => {
            try {
                if(productName) {
                    const data = await dispatch(fetchProductByNameAsync({ productName: productName }));
                }
            } catch (error) {
                console.error("Error fetching product: ", error)
            }
        }

        if (productType != currentProductType && !fromTypeSelection) {
            
            
            dispatch(resetState())
            fetchProduct();
        } else {
            dispatch(resetState())
            fetchProductByName()
        }
    }, [productType, productName, dispatch])


    useEffect(() => {
        
        if (productLoaded.length > 0) {
            setCurrentPrice(productLoaded[0].price)
            setProductList(productLoaded)
            setSelectedOption(productLoaded[0].name)
            setSelectedSize(productLoaded[0].size)
            setSelectedLevel(productLoaded[0].level)
            setCurrentPrice(productLoaded[0].price)
            dispatch(setBasePrice(productLoaded[0].price))
        }
    }, [productLoaded])

    useEffect(() => {
        
        if (!isLoading && productList.length > 0) {
            let productFound = productList.find((product) => {
                return product.size === selectedSize && product.level === selectedLevel;
            });
            setCurrentPrice(productFound!.price)
            setBasePrice(productFound!.price)
            dispatch(setBasePrice(productFound!.price))
            dispatch(resetColors())
        }
    }, [isLoading, selectedLevel, selectedSize])

    const handleSubmit = () => {
        let productPrice = 0
        
        
        let productFound = productList.find((product: Product) => 

            product.name === selectedOption && product.size == selectedSize && product.level == selectedLevel
        )
        
        

        if (productFound) {
            if (colorStateFromSelector.length > 4) {
                
                
                productPrice = productFound.price + ((colorStateFromSelector.length - 4) * 5)
            } else {
                productPrice = productFound.price
            }
            let addProductToCart = {
                id: productFound.id,
                name: productFound.name,
                quantity: 1,
                colors: colorStateFromSelector,
                description: productFound.description,
                price: productPrice,
                size: selectedSize,
                type: productType,
                level: selectedLevel,
                additionalInfo: textareaValue
            };
            dispatch(addToCart(addProductToCart));
            dispatch(resetColors())
            setExpandColors(false)
        } else {
            console.error(`Product with name '${selectedOption}' not found.`);
        }
    };


    const handleSize = (option: string) => {
        setSelectedSize(option)
    }

    const handleLevel = (option: string) => {
        setSelectedLevel(option)
    }

    const handleSelect = (option: string) => {
        setSelectedOption(option);
    };

    
    const handleChangeColor = (data: string[]) => {
        
        
        if (data.length > 4) {
            let additionalPrice = (data.length - 4) * 5
            
            let newCurrentPrice = baseSelectedPrice + additionalPrice
            setCurrentPrice(newCurrentPrice)
        } else {
            setCurrentPrice(baseSelectedPrice)
        }
    }

    const handleExpandColors = () => {
        setExpandColors(!expandColors)
    }

    let textWithLineBreaks
    if(fromTypeSelection) {
        textWithLineBreaks = detailsMap[productName!].split('\n\n').map((line, index) => (
            <React.Fragment key={index}>
                <p className="!mt-4 text-base font-normal leading-[27px] !text-gray-800" dangerouslySetInnerHTML={{__html: line}} />
            </React.Fragment>
        ));
    } else {
        textWithLineBreaks = detailsMap[productType].split('\n\n').map((line, index) => (
            <React.Fragment key={index}>
                <p className="!mt-4 text-base font-normal leading-[27px] !text-gray-800" dangerouslySetInnerHTML={{__html: line}} />
            </React.Fragment>
        ));
    }
    
    if (isLoading) {
        return (
            <div className="container mx-auto flex text-center min-h-40">
                <Button variant="text" loading={true} placeholder=" "className="m-auto">
                    Loading
                </Button>
            </div>
        )
    }
    
return (
    <section className="md:py-16 px-8 h-full">
    <div className="mx-auto container md:flex md:flex-row h-full md:gap-10">

    <Carousel placeholder={undefined} className="rounded-xl md:basis-2/5 md:h-100" 
    navigation={({ setActiveIndex, activeIndex, length }) => (
        <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
        {new Array(length).fill("").map((_, i) => (
            <span
            key={i}
            className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                activeIndex === i ? "w-8 bg-black" : "w-4 bg-black/50"
            }`}
            onClick={() => setActiveIndex(i)}
            />
        ))}
        </div>
    )}
    >
        {productImages.map((image: string, index: number) => {
            return (
                <div className="md:flex md:justify-center md:h-full p-20"><img src={`/${image}`} key={index} className="max-w-100 self-center	" /></div>
            )
        })}
    </Carousel>

      <div className="md:flex md:basis-3/5 h-100 overflow-y-auto">
        <div className="inline-block flex-none w-full h-full">
        <h3 className="block font-sans text-3xl antialiased font-semibold leading-snug tracking-normal text-inherit md:text-left text-center mt-3 md:mt-0">
        {productName}{productType}
        </h3>
            {textWithLineBreaks}

            <h6 className="mt-8 block font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit">
                Style
            </h6>
            <div className="mb-8 flex items-center gap-2 color-palette flex-wrap">
            {productTypes.map((product:string) => (
                <Button variant={(product == selectedOption) ? "filled" : "outlined"} className="me-3" placeholder={undefined} onClick={() => handleSelect(product)} >{product}</Button>
            ))}
            </div>

            {size[0] != null ? (
                <div>
                    <h6 className="block font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit">Size</h6>
                    <div className="mb-8 flex items-center gap-2 color-palette flex-wrap">
                    {size.map((sz:string) => (
                        <Button variant={(sz == selectedSize) ? "filled" : "outlined"} className="me-3" placeholder={undefined} onClick={() => handleSize(sz)} >{sz}</Button>
                    ))}
                    </div>
                </div>
            ) : ""}


            {level[0] != null ? (
                <div>
                    <h6 className="block font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit">Level</h6>
                    <div className="mb-8 flex items-center gap-2 color-palette flex-wrap">
                    {level.map((lvl:string) => (
                        <Button variant={(lvl == selectedLevel) ? "filled" : "outlined"} className="me-3" placeholder={undefined} onClick={() => handleLevel(lvl)} >{lvl}</Button>
                    ))}
                    </div>
                </div>
            ):""}

            <ColorPalette colorList={paletteList} title={"Choose up to 4 complimentary colours"} numOfColors={7} fromSecondary={false} changeColor={handleChangeColor}/>

            <div className="mb-4 flex w-full items-center gap-3 md:w-1/2 ">
                <Button disabled={colorStateFromSelector.length <= 0 ? true : false}
                onClick={handleSubmit}  color="gray" className="w-52" placeholder={undefined}>
                Add to Cart &mdash; ${(currentPrice).toFixed(2)}
            </Button>
            </div>

            <div className="text-sm  !text-gray-600">
                <h6 className="block font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit">Disclaimer</h6>
                <p>Snuffle products & toys should never be used to replace proper exercise or training. All products from The Curious One should be used under supervision.</p>
                <p>No toy is indestructible! If your pet starts chewing on the product, please remove it and review potential reasons such as frustration (you may need to start even slower to teach your pet how to snuffle!).
    Feel free to reach out if you have any questions.</p>
            </div>
        </div>
      </div>
    </div>
  </section>
)

}

export default ProductSelectionPage;
